import React from "react"
import { Container, Figure } from "react-bootstrap"
// import ScrollableAnchor, { configureAnchors } from "react-scrollable-anchor"
import { Helmet } from "react-helmet"

import Layout from "../../components/MMSAmazonComponents/components/layout"
import SEO from "../../components/MMSAmazonComponents/components/seo"

import tvimage from "../../components/MMSAmazonComponents/images/TV.png"
import GiftCard from "../../components/MMSAmazonComponents/images/gift-card.png"
import AmazonBundle from "../../components/MMSAmazonComponents/images/amazon-bundle.png"
import Firetv from "../../components/MMSAmazonComponents/images/firetvstick.png"

// Star Backgrounds - Desktop
import pageStarsLeftTop from "../../components/MMSAmazonComponents/images/page-stars-left-top.png"
import pageStarsLeftBottom from "../../components/MMSAmazonComponents/images/page-stars-left-bottom.png"
import pageStarsRightTop from "../../components/MMSAmazonComponents/images/page-stars-right-top.png"
import pageStarsRightBottom from "../../components/MMSAmazonComponents/images/page-stars-right-bottom.png"
import ogImg from "../../components/MMSAmazonComponents/images/ogimg.png"
import freepack from "../../components/MMSAmazonComponents/images/freepack.png"
// configureAnchors({ offset: -30 })

function Prizes() {
  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="../../MMSAmazon/css/app.css" />
        <link rel="stylesheet" href="../../MMSAmazon/css/responsive.css" />
      </Helmet>
      <Layout pageInfo={{ pageName: "index" }}>
        <SEO
          title="Amazon prime UK | Prizes"
          keywords={[`gatsby`, `react`, `bootstrap`]}
        />
        {/* <ScrollableAnchor id={"prizes"}> */}
          {/* <ScrollableAnchor id={"main"}> */}
          <section className="main-content prizes py-4 py-md-5 position-relative">
            <Container>
              <div className="text-white px-4 text-center">
                <h4 className="text-center text-uppercase mb-4">
                  We have some Amazing prizes up for grabs
                </h4>
                <div>
                  <img
                    className="mb-3 img-fluid"
                    src={tvimage}
                    alt="Tv and Soundbar"
                  />
                  <h5 className="text-center text-uppercase mb-0">
                    10 X Home cinema Systems
                  </h5>
                  <h5 className="text-center text-uppercase mb-5">
                    A Tv and SoundBar
                  </h5>
                </div>
                <div>
                  <img
                    className="mt-5 mb-3 img-fluid"
                    src={Firetv}
                    alt="Amazon Gift card"
                  />
                  <h5 className="text-center text-uppercase mb-0">
                    2,900x Amazon Fire TV Sticks provided
                  </h5>
                  <h5 className="text-center text-uppercase mb-5">
                    in the form of a £50 amazon.co.uk gift card
                  </h5>
                </div>
                <div>
                  <img
                    className="mb-5 img-fluid"
                    src={AmazonBundle}
                    alt="Amazon Bundle"
                  />
                  <h5 className="text-center text-uppercase mb-0">
                    2,000 x M&M's Bundles
                  </h5>
                  <h5 className="text-center text-uppercase mb-5">
                    Each bundle contains 2 x T-shirts, 2 X cushions,
                    <span className="d-md-block">
                      2 X pairs of socks and 4 x packs of chocolate
                    </span>
                  </h5>
                </div>
                <div>
                  <img
                    className="mb-5 img-fluid"
                    src={freepack}
                    alt="Free pack"
                  />
                  <h4 className="text-center text-uppercase">
                    80,000x coupons for a free pack of confectionery.
                  </h4>
                </div>
                <img className="d-none" src={ogImg} alt="og image" />
              </div>
            </Container>

            <Figure className="mb-0 page-stars-left-top position-absolute">
              <Figure.Image src={pageStarsLeftTop} alt="Stars Left Top" />
            </Figure>
            <Figure className="mb-0 page-stars-left-bottom position-absolute">
              <Figure.Image src={pageStarsLeftBottom} alt="Stars Left Bottom" />
            </Figure>
            <Figure className="mb-0 page-stars-right-top position-absolute">
              <Figure.Image src={pageStarsRightTop} alt="Stars Right Top" />
            </Figure>
            <Figure className="mb-0 page-stars-right-bottom position-absolute">
              <Figure.Image
                src={pageStarsRightBottom}
                alt="Stars Right Bottom"
              />
            </Figure>
          </section>
        {/* </ScrollableAnchor> */}
      </Layout>
    </>
  )
}

export default Prizes
